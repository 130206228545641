@media print {

    #bs-header {
        width: 1680px;
        float: left;
        p.bs-header-description {
            margin-right: 3%;
        }

        #bs-header-logo {
            img {
                margin-left: -220px;
            }
        }
    } 

    #bs-bg-questinos {

        .bs-questions-title {
            position: absolute;
            left: 70px;
            top: 120px;
            text-transform: uppercase;


        }
        .bs-questions-description {
            p {
                text-align: left;
                font-size: 23px;
                padding-top: 80px;
                padding-left: 60px;
                width: 80%;
            }
        }
        .bs-questions-typs-title {
            position: absolute;
            font-size: 20px;
            top: 320px;
            left: 70px;
        }
        .bs-questions-typs {
            ul {
                position: absolute;
                top: 370px;
                left: 30px; 
            }
        }

        .bs-main-navigation {
            display: none;
        }
        
    }

}
