body {
    /* All backgrounds will be cover fixed */
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    background-repeat: no-repeat;

    /* Defualt font family is robot slab serif */
    font-family: $roboto-slab-sf;

    /* Elements can't be selected */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;  
    user-select: none;

    min-height:100%;
    position:relative;

    /* Changed background color to fix footer lose space (client request) */
    background-color: #D6D6D6 !important;

    header {
        color: $default-primary;
        .subheader {
            color: $default-primary;
        }
        hr.bs-separator {
            width: 419px;
            border: 1px solid $default-primary;
        }
        h2 {
            font-size: 78px;
        }
    }

    p {
        color: $default-primary;
    }

    .bs-logo {
        width: 179px;
        height: 60px;
        margin-left:-30px;
        img {
            height: 60px;
        }
    }    
    button:focus {
        outline: 0;
    }

    #input-option {
        height: 50px;
        color: #4383AF !important;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        font-family: $roboto;
        margin: 10px 0;
        display: inline-block;
        @include border-radius(10px);
        background-color: #D7D7D7;
        white-space: nowrap;
        span.bs-button-text {
            color: #3E81B0;
        }
        input {
            background-color: transparent;
            border: 0;
            height: 100%;
            color: #4383AF;
            margin: 0 0 -25px !important;
        }
        .bs-dashed {
            /*border-top: 2px dotted;*/
            color: #A0A0A0;
        }
        span.bs-button-text:hover {
            color: #40C799;
        }
        @-moz-document url-prefix() {
            margin-bottom: -23px;
        }
    }

    @media all and (-ms-high-contrast:none)
    {
        /* IE11 */
        #input-option  { 
            margin-bottom: -23px;
        }
    }
    @supports (-ms-accelerator:true) {
        #input-option{
            margin-bottom: -23px;
        }
    }
    button {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        font-family: $roboto;
        white-space: nowrap;
        a {
            text-decoration: none;
            color: $default-primary;
        }
        a:hover {
            text-decoration: none;
            color: $default-primary;
        }
        &.bs-last-page {
            width: 200px;
            height: 48px;
            margin-left: 10px;
            margin-right: 10px;
        }
        &.bs-circular-button {
            font-family: $roboto-sf !important;
            background-color: #469F99;
            color: $default-primary;
            @include border-radius(100%);
            width: 140px;
            height: 140px;
            opacity: 1;
            margin: 10px;
            font-weight: 800;
            @include breakpoint(large)
            {
                width: 170px;
                height: 170px;
            }
        }
        &.bs-circular-button:hover {
            background-color: #F2A31B;
        }
        &.bs-circular-button-clicked { 
            background-color: #F2A31B;
        }
        &.bs-button-transparent {
            color: #3BA3A6;
            border: 2px solid #45D5A2 !important;
            background-color: rgba(40, 40, 40, 0.7);
        }
        &.bs-button-transparent:hover {
            border: 2px solid #FBAF32 !important;
            background-color: rgba(40, 40, 40, 0.7);
        }
        &.bs-button-gray {
            height: 50px;
            margin: 10px 0;
            padding-left: 10px;
            padding-right: 10px;
            color: #4383AF;
            @include border-radius(10px);
            background-color: #D7D7D7;
            overflow: hidden;
            white-space: normal;
            span.bs-button-text {
                color: #3E81B0;
            }
            span.bs-button-text:hover {
                color: #40C799;
            }

            &:hover {
                background-color: #fff;
                span {
                    color: #40C799;
                }
            }
        }
        input {
            background-color: transparent !important;
            border: 0 !important;
            height: 100% !important;
        }
    } 
    
    .bs-invite-friend {
        display: inline-block;
        padding-top: 18px;
        font-size: 1.3rem;
        width: auto;
        position: relative;
        font-weight: 600;
        font-family: "Roboto Slab", serif;
    }

    a.bs-button-social{
        background-color: #469F99;
        color: #FFF;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        margin-left: 10px;
        display: inline-block;
        /* float: left;*/
        margin: 0 auto;
    } 
    /* Welcome Splash Screen */
    .welcome-wrapper {
        height: 101%;
    }

    .bs-landing-logo {
        margin: 30px auto;
    }
   

    .landing-description {
        .bs-separator {
            width: 60%;
            border: 1px solid $default-primary;
        }
        h1 {
            font-family: $roboto-slab;
            font-weight: 300;
            line-height: 100%;

        }

        h2 {
            font-family: $roboto-slab;
            font-weight: 300;
            line-height: 100%;
            position: relative;
            span.bs-treadmark {
                position: relative;
                font-size: 5%;
                vertical-align: top;
                @include breakpoint(medium) {
                    line-height: 11.1;
                }
                @include breakpoint(small only) {
                    line-height:11.4;
                }

                @include breakpoint(medium up) {
                    line-height: 15.1;
                }
                @include breakpoint(xlarge up) { 
                    line-height:11.1;
                }
                @include breakpoint(xxlarge) { 
                    line-height:15.1;
                }
            }
        }
        p {
            font-family: $roboto-slab;
            font-weight: 200;
        }
        @include breakpoint(small only) {
            .bs-separator {
                width: 80%;
            }
            h1 {
                font-size: 11vw;
            }

            h2 {
                font-size: 24vw;
                line-height: 80%;
            }
            p {
                font-size: 3.5vw;
            }
        }
        @include breakpoint(medium) {
            .bs-separator {
                width: 100%;
            }
            h1 {
                font-size: 11vw;
            }

            h2 {
                font-size: 25vw;
            }
            p {
                font-size: 3.5vw;
            }
        }
        @include breakpoint(xlarge) {
            .bs-separator {
                width: 40%;
            }
            h1 {
                font-size: 4.15vw;
            }

            h2 {
                font-size: 9.7vw;
                line-height: 80%;
            }
            p {
                font-size: 1.3vw;
            }
        }
        @include breakpoint(xxlarge) {
            .bs-separator {
                width: 50vw;
            }
            h1 {
                font-size: 7.15vw;
            }

            h2 {
                font-size: 15.7vw;
                line-height: 100%;
            }
            p {
                font-size: 2.3vw;
            }
        }
    }

    .bs-footer-button {
        position: fixed;
        bottom: 0px;
        margin: 5% auto;
        left: 0;
        right: 0;
        button {
            &.bs-circular-button-landing {
                background-color: #FFF;
                color: #E6A23A;
                border: 1px solid #D5A380;
                @include border-radius(100%);
                width: 140px;
                height: 140px;
                cursor: auto;
                @include breakpoint(small only)
                {
                    width: 110px;
                    height: 110px;
                }
            }
            p {
                color: #E6A23A;
                font-size: 10px;
                font-family: $roboto-slab-sf;
                text-transform: none;
                font-weight: normal;
                font-size: 16px;
                margin-bottom: 0 !important;
            }
        }
    }

    /* Page Wrapper */

    .pace-wrapper {
        /* All backgrounds will be cover fixed */
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
        background-repeat: no-repeat;

        transition: background 0.5s linear;

        .questions-separator {
            width: 30%;
            border: 1px solid #FBAF32;
            margin-top: 0.8rem;
            @include breakpoint(medium)
            {
                width: 20%;
            }
        }

        #bs-bg-questinos{
            /*margin-top: 90px;*/
            padding-bottom: 50px;
        }

        .bs-buttons-container > .columns {
            button, #input-option {
                width: 100%;
            } 
        } 

        /*#bs-first, #bs-guest {
            .bs-buttons-container {
                @media screen and (min-width: 1px) and (max-width: 421px) {
                    .bs-circular-button {
                        height: 110px !important;
                        width: 110px !important;
                        font-size: rem-calc(12);
                    }
                }
                @media screen and (min-width: 750px) and (max-width: 1023px) {
                    width: 55%;
                }
                @media screen and (min-width: 600px) and (max-width: 750px) {
                    width: 70%;
                }
            }
            }*/

            /* Navbar */
            #bs-header {
                background-color: #FFF;
                height: auto;
                opacity: 1;
                font-family: $roboto-slab;
                font-weight: 300;
                transition: background 2s linear;
                opacity: 0.8;
                .top-bar-left {
                    margin-top: 10px;
                    opacity: 1;
                }
                .top-bar-right {
                    margin-top: 17px;
                    @include breakpoint(large up) {
                        margin-right: -20px;
                    }

                    @-moz-document url-prefix() {
                      margin-top: 22px;
                  }
                  //IE hack
                  @media all and (-ms-high-contrast:none)
                  {

                     margin-right:50px;
                     font-size:27px;
                     @supports (-ms-accelerator:true) {
                      margin-right:50px;
                      font-size:27px;
                  }
              }

          }
          .bs-header-title {
            color: #5C94BA;
            font-size: 2.12vmax;
            opacity: 1;
        }
        .bs-header-description {
            color: #FBAF32;
            font-size: 1.4vmax;
            opacity: 1;
        }
        p {
            margin-bottom: 0px;
            line-height: 1;
        }
        @include breakpoint(small only) {
            .bs-header-title, .bs-header-description, .top-bar-left {
                text-align: center;
                font-size: rem-calc(14);
            }

            .top-bar-left {
                margin-top: 20px;
            }
            div.bs-logo {
                margin: 0 auto;
            }
        }

        @include breakpoint(medium only) {
            .bs-header-title, .bs-header-description, .top-bar-left {
                font-size: rem-calc(18);
                margin-top: 10px;
            }
        }
    }

    /* Introduction */
    #bs-introduction {
       margin-top: 60px;
       .questions-separator {
          margin-top: 0.4rem;
      }
      .bs-questions-title {
        font-family: $roboto-slab;
        color: #FFF;
        font-size: rem-calc(14);
        font-weight: 600;
        padding: 0 auto;
    }
    .bs-questions-description
    {
        font-family: $roboto-slab;
        font-size: rem-calc(24);
        color: #FFF;
        padding-bottom: 20px;
    }
    #bs-start-quiz {
        padding: 1em 2.2em;
    }
    .bs-white-box {
        background-color: #F6F8F7;
        font-weight: normal;
        border-radius: 10px;
        font-size: rem-calc(16);
        color: #4685AF;
        padding: 28px;
        margin-top: 50px;
        p {
            color: #4685AF;
        }
        a {
            text-decoration: underline;
        }
        @include breakpoint(large) {
            width: 52%;
        }
    }
    @include breakpoint(small only) {
        .bs-white-box {
            p {
                font-weight: bold;
            }
        }
        .bs-questions-description {

        }
    }
    @include breakpoint(medium only) {
        .bs-white-box {
            p {
                font-weight: bold;
            }
        }
        .bs-questions-description {

        }
    }
}

.bs-question-content, #bs-last-step {
    button {
        &.start-over, &.next, &.back, &.skip {
            @include breakpoint(large) {
                width: 15% !important;
                margin: 14px;
            }
        }  
    }
    .bs-share-buttons{
        margin: 0 auto;
    }
    .bs-share-social {
        margin-left: 10px;
        margin-right: 10px;
    }
    .bs-share-fb {
        background: url("../img/social/facebook.png") no-repeat;
        width: 60px;
        height: 60px;
    }
    .bs-share-tw {
        background: url("../img/social/twitter.png") no-repeat;
        width: 60px;
        height: 60px;
    } 
    .bs-share-mail {
        background: url("../img/social/email.png") no-repeat;
        width: 60px;
        height: 60px;
    }
    .bs-questions-title {
        display: inline-block;
        padding-top: 48px;
        font-size: rem-calc(24);
        width: auto;
        position: relative;
        font-family: $roboto-slab-sf;
    }
    .bs-questions-title-second {
        display: inline-block;
        padding-top: 48px;
        font-size: rem-calc(24);
        width: auto;
        position: relative;
        font-family: $roboto-slab-sf;
        font-weight: 600;
    }
    .bs-questions-description-secondary
    {
        font-size: rem-calc(14);   
        /*font-family: "Georgia Italic",Georgia;*/
        font-style: italic;
        font-weight: 600;
        margin-bottom: 28px;
    }
    .bs-predictive-text {
        color: #3ba3a6;
        margin-top: 50px;
        margin-bottom: 50px;
        font-size: rem-calc(24);
        font-family: $roboto-slab;
        font-weight: 600 !important;
        span.bs-result-text {
            color: #fff;
            width: 50%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        @include breakpoint(medium only) {
            font-weight: 600 !important;
            span {
                font-weight: 600 !important;
            }
        }
        @include breakpoint(small only) {
            font-weight: 600 !important;
            span {
                font-weight: 600 !important;
            }
        }
    }
    .bs-questions-typs {
        font-family: $roboto-slab-sf;
        border: 1px solid #fff;
        color: #fff;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        font-weight: 600;
        @include border-radius(25px);
        p {
            color: #FAB131;
            overflow: auto;
            word-wrap: break-word;
        }
        ul {
            div.first-column {
                padding-left: 30px; 
            }
            list-style-position:inside !important;
            li{
                text-align: left;
            }
            .bs-white-box {
                background-color: #F6F8F7;
                font-weight: normal;
                border-radius: 10px;
                font-size: rem-calc(16);
                color: #4685AF;
                padding: 34px;
                margin-top: 50px;
                p {
                    color: #4685AF;
                    margin: 0px;
                }
                a {
                    text-decoration: underline;
                }
                @include breakpoint(large) {
                    width: 52%;
                }
            }
            @include breakpoint(small only) {
                .bs-white-box {
                    p {
                        font-weight: bold;
                    }
                }
                .bs-questions-description {
                }
            }
            @include breakpoint(medium only) {
                .bs-white-box {
                    p {
                        font-weight: bold;
                    }
                }
                .bs-questions-description {
                }
            }
            #bs-help-modal {
                background-color: #3175A4;
                color: #fff;
                height: 450px;
                border-radius: 20px;
                font-family: $roboto-sf;
                button {
                    color: #fff;
                }
                .bs-modal-title {
                    margin: 0 auto;
                    font-size: 20px;
                    border-bottom: 1px solid #FFA417;
                    width: 30%;
                }
                p {
                    margin: 30px 0px;
                }
            }
        }
    }
    .bs-circular-button
    {
        font-size: rem-calc(16);
        font-family: $roboto;
        text-transform: uppercase;
    }
    .bs-questions-help {
        width: 45px;
        height: 45px;
        float: right;
    }
    .bs-questions-buttonfit {
        @include border-radius(50%);
        background-color: #5C94BA;
        width: 25px;
        height: 25px;
        font-size: 15px;
        line-height: 1.8;
        span {
            margin-top: -6px;
        }
        .bs-question-symbol {
            color: #fff;
            text-decoration: none;
        }
    }
    .bs-input-brands-container {
        textarea {
            height: 200px;
            border-radius: 12px;
            background-color: #d7d7d7;
            color: #4383AF;
            font-weight: bold;
            text-transform: uppercase;
            resize: none;
            zoom: none;
            padding: 18px 32px;
        }
    }
    .bs-email-opt-in-box {
        padding: 15px;
        background-color: #F6F8F7;
        font-weight: bold;
        font-size: rem-calc(16);
        border-radius: 10px;
        color: #4685AF;
        label, p {
            color: #4685AF;
            font-weight: bold;
            font-size: rem-calc(14);
            text-align: center;
        }
        small {
            color: #000;
            font-size: rem-calc(12);
        }
    }
    #bs-email-modal,
    #bs-share-modal,
    #bs-email-modal-partner {
        background-color: #3175A4;
        color: #fff;
        border-radius: 20px;
        font-family: $roboto-sf;
        margin: 0 auto;
        overflow: hidden;
        input {
            height: 45px;
            margin-bottom: 3px;
        }
        .bs-share-buttons {
            margin: 20px auto;
        }
        .bs-modal-title {
        	width: 70%;
        }
        button {
            color: #fff;
            width: 250px;
            margin-top: 10px;
            span {
                a:visited {
                    text-decoration: none;
                    color: #fff;
                }
                a:active {
                    text-decoration: none;
                }
                a:hover {
                    text-decoration: none;
                }
                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
            &.close-button {
                padding-left: 0px;
                padding-top: 5px;
                width: 40px;
            }
        }
        .button-share{                                     
            padding-top: 13px; 
        }

    }


    /* Room Slider */
    .bs-room-size-container {
        font-family: $roboto-sf;
        font-size: rem-calc(23);
        font-weight: 600;
        .room-design {
            height: 290px;
            margin-bottom: 60px;
            border: 2px solid #fff;
            font-family: 'Roboto';
            font-size: rem-calc(36);
            font-weight: 600;
            .show-small-vslider {
                display: none;
            }
            @include breakpoint(small) {
                width: 100%;
            }
            @include breakpoint(medium) {
                width: 45%;
                height: 200px;
                margin-bottom: 40px;
            }
            @include breakpoint(xxlarge) {
                width: 50%;
            }
        }
        @include breakpoint(medium) {
            .hide-small-vslider {
                display: block;
                height: 200px;
            }
            .show-small-vslider {
                display: none;
                height: 200px;
            }
        }
        @include breakpoint(small only) {
            .hide-small-vslider {
                display: none;
            }
            .show-small-vslider {
                display: block;
            }
        }
        .slider {
            height: 3px;
        }
        .vertical {
            width: 3px;
            height: 260px;
            @include breakpoint(medium) {
                height: 200px;
            }
        }
        .vertical-text
        {
            height: 300px;
            padding-top: 100px;
            @include breakpoint(medium) {
                height: 200px;
            }
        }
        .slider-handle {
            border-radius: 50%;
            background-color: #3BA3A6;
            width: 2.1rem;
            height: 2.1rem;
            z-index: 9999;
        }
        .horizontal-slider {
            @include breakpoint(medium)
            {
                float: left;
                width: 32%;
            }
            @include breakpoint(xlarge)
            {
                float: left;
                width: 34%;
            }
        }
        .room-design-length-text {
            margin-top: 234px;
            @include breakpoint(medium) {
                margin-top: 144px;
            }
        }
        .room-design-width-text {
            margin: 110px 0px 0px 15px;
            @include breakpoint(medium) {
                margin: 70px 0px 0px 15px;
            }
        }
    }
    #bs-help-modal {
        background-color: #3175A4;
        color: #fff;
        height: 450px;
        border-radius: 20px;
        font-family: $roboto-sf;
        button {
            color: #fff;
        }
        .bs-modal-title {
            margin: 0 auto;
            font-size: 20px;
            border-bottom: 1px solid #FFA417;
            width: 30%;
        }
        p {
            margin: 30px 0px;
        }
    }
    .bs-slider-component {
        position: relative;
    }
}
/* Circular Slider */
.rs-start, .rs-end {
    display: none !important;
}
#bs-guest-child, #bs-me-4 {
    .rs-handle {
        background-color: #3BA3A6;
    }
    .rs-bg-color, .rs-path-color {
        background-color: transparent;
    }
    .rs-border {
        border: 1px solid #FFF;
    }
}
#bs-guest-child, #bs-me-4 {
    .rs-tooltip-text {
        font-size: rem-calc(148) !important;
        font-family: $roboto !important;
        font-weight: 300 !important;
        margin-top: -80px !important;
        line-height: 90px !important;
        .inner-text {
            font-size: rem-calc(18) !important;
            font-weight: bold !important;
            margin-top: 40px;
        }
        @include breakpoint(small only) {
            font-size: rem-calc(120) !important;
            margin-top: -70px !important;
            line-height: 100px !important;
            .inner-text {
                font-size: rem-calc(20) !important;
                margin-top: 15px;
            }
        }
    }
}

/* Button navigation */
.bs-main-navigation {
    padding-top: 1em;
    button {
     padding: 1em;
 }
}
/* Footer about */
.fix-footer {
    padding-bottom: 15px;
}
footer {
            /*clear: both;
            background:#D6D6D6;
            height:64px;
            padding-top: 32px;
            position: absolute;
            bottom:0;
            left:0;*/

            width:100%;

            .bs-footer {
                color: #000;
                font-family: $roboto;
                font-size: rem-calc(16);
                padding: 15px 0;
                text-align: center;
                background:#D6D6D6;
            }

        } 

        #bs-circular-progress {
            .bs-new-circular-progress {
                padding-top: 13px;
                position: relative;
                z-index: 999;
            }
            height: 157px;
            overflow: hidden;
            position: relative;
            z-index: 999;
            // Hack FF
            @-moz-document url-prefix() {
                height: 144px;
            }
            #bs-circular-progress-bar {
                -ms-transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                left: 50%;
                position: absolute;
                margin-left: -119px;
                top: 38px;
                // Hack FF
                @-moz-document url-prefix() {
                    top: 25px;
                }
            }

             /*//
            //IE hack
            @media all and (-ms-high-contrast:none) {
                margin-top:-165px;
                margin-left: 13%;
                }*/
            }
        }

        .reveal {
            border: 0;
            background-color: #3a6e8e;
            color: #fff;
            border-radius: 10px;
            opacity: 0.8;
            max-height: 100%;
            font-weight: 400;
            button {
                color: #fff;
            }
            .bs-modal-title {
                margin: 0 auto;
                font-size: rem-calc(14);
                border-bottom: 1px solid #FFA417;
                font-family: $roboto-slab-sf;
                line-height: 28px;
                width: 30%;
            }
            p {
                font-family: $roboto-slab;
                font-size: rem-calc(24);
            }
        }

        #printFullResults, #quickResults {
            span {
                bottom: 9px;
                position: relative;
            }

        }

        @media print {

            body {
                background-color: #fff !important;
                color: #000 !important;
            } 
            #bs-footer-section {
                display: none;
            } 
            #bs-circular-progress {
                display: none;
            }
            .large {
                #bs-bg-carousel {
                    .item img {
                        display: none;
                    }
                }
            }
            .bs-share-buttons {
                display: none !important;
            }
            .answersTitle {
                position: absolute !important;
                top: 1680px !important;
                font-size: 29px !important;
            }
            .pace-wrapper {
                #bs-header{
                    display: none;
                }
                .questions-separator {
                    display: none;
                }
                img  {
                    width: 202px;
                    height: 85px;
                    position: absolute;
                    left: 36px;
                    top: 11px !important;
                }
                img.logoPrintPageTwo {
                    width: 154px;
                    height: 65px;
                    position: absolute;
                    left: 16px;
                    top:  1550px !important;
                }
                #bs-header-print {
                    position: absolute;
                    background-color: #DDDFDE;
                    height: 85px;
                    width: 1300px;
                    top: 20px;
                    margin: 0 auto;
                    padding-right: 20px !important;
                    opacity: 0.8;
                    font-family: $roboto, serif;

                    .bs-header-title {
                        color: #5C94BA;
                        margin-right: 175px !important;
                        font-size: 36px;
                    }
                    .bs-header-description {
                        color: #FBAF32;
                        font-size: 24px;
                        font-style: italic;
                        margin-top: -31px;
                        margin-right: 175px  !important;
                    }
                }

                .headerPrint {
                    #bs-header-print {
                        display: block !important;
                        position: absolute !important;
                        top: 1550px !important;

                        #bs-header-logo {
                            position: absolute;
                            top:  1620px !important;
                            left: 300px !important;
                        }

                    }
                }
                #headerPrintSeparatorInitial {
                    position:absolute;
                    top: 80px !important;
                    left: 30px !important;
                    width: 500px !important;
                }
                #headerPrintSeparator {
                    position:absolute;
                    top: 1600px !important;
                    width: 1900px !important;
                }
                #headerPrintSeparatorEnd {
                    position:absolute;
                    top: 1720px !important;
                    width: 1900px !important;
                }
                #bs-header-logo {
                    position:absolute;
                    left: 280px !important;    
                }
                .resultOptions  {
                    margin-top: 200px !important;    
                    text-align: left;
                    float: left;
                    ul {
                        li{
                            list-style-type: none !important;
                            font-size: 18px !important;
                            margin-bottom: 20px !important;
                            span.h-opt {
                                font-weight: bold;
                                margin-bottom: 20px !important;
                                margin-left: 35px !important;
                            }
                            span.h-val {
                                margin-left: 65px !important;
                                margin-bottom: 25px !important;
                            }
                        }
                    }
                }
                #bs-last-step {
                    .bs-predictive-text {
                        font-size: 20px !important;
                        font-family: 'Roboto Slab';
                        width: 1100px !important;
                        font-style: italic;
                        padding: 12px;
                        margin: 0 auto;
                        margin-top: 220px !important;
                        font-weight: 600 !important;
                        margin-left: -65px !important;
                        .bs-result-text {
                            font-weight: bolder;
                            color: #000!important;
                        }
                    }



                    .bs-questions-title-second {
                        display: none !important;
                    }
                    .bs-questions-typs {
                        margin-top: 350px !important;
                        display: none! important;
                        .bs-questions-typs-title {
                            position: absolute;
                            top: 200px;
                            left: 50px;

                        }
                        ul {
                            margin-top: 250px !important;
                            margin-left: 75px !important;
                            li {
                               margin-top: 20px !important; 
                               margin-left: 0px !important;
                               font-size: 20px !important;
                               width: 500px !important;
                           }
                       }
                       .bs-questions-typs-title {
                        margin-top: 150px;
                        padding-top:  200px;
                    }
                }
                .questions-separator {
                    margin-top: 40px !important;
                    width: 1900px !important;
                }
                .bs-questions-title {
                    float: left;
                    color: black;
                    font-size: 15px;
                    margin-left: -27px;
                    margin-bottom: 40px;
                }

            }
        }
    }
}