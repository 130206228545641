// Font
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,700|Roboto+Slab:400,100,700,300);

// Font Variables
$roboto: 'Roboto';
$roboto-sf: 'Roboto', serif;
$roboto-slab: 'Roboto Slab';
$roboto-slab-sf: 'Roboto Slab', serif;

// Font Color
$default-primary: #FFF;

// Button Border
$default-border: 25px;